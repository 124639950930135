import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../src/layouts/PageLayout'
import Icon from '../../src/components/Icon'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
Moment.locale('nl')

const DriversExperienceTemplate = ({ data }) => {
	let postData = data.wpDriversExperience

	const renderRatingStars = amountOfStars => {
		const stars = []

		for (let i = 0; i < amountOfStars; i++) {
			stars.push(<Icon width="1rem" key={i} height="1rem" color="#FFC107" margin="0 0 0 0" icon={faStar} />)
		}

		return stars
	}

	return (
		<PageLayout pageData={postData}>
			<ContentWrapper>
				<Content>
					<Title>{postData.postContent.brand}</Title>
					<SubtitleBlock>
						<LeftContainer>
							<Model>{postData.postContent.model}</Model>
							<Rating>{renderRatingStars(postData.postContent.rating)}</Rating>
						</LeftContainer>
						<RightContainer>
							<Name>{postData.postContent.authorName}</Name>
							<Date>{Moment(postData.date).format('LL')}</Date>
						</RightContainer>
					</SubtitleBlock>
					<PostContentText>{postData.postContent.content}</PostContentText>
				</Content>
				<DriversExperienceImage
					image={getImage(postData.postContent.image.localFile)}
					alt={postData.postContent.image.altText}
				/>
			</ContentWrapper>
		</PageLayout>
	)
}

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 180px 0;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		max-width: 45%;
		padding-right: 25px;
	}
`

const Title = styled.h1`
	margin: 0;
`

const SubtitleBlock = styled.div`
	display: flex;
	justify-content: space-between;
`

const PostContentText = styled.p``

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
`
const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const Model = styled.p`
	margin: 0;
	color: #8c8c8c;
	font-size: 1rem;
`
const Rating = styled.div`
	margin-bottom: 20px;
`

const Name = styled.p`
	font-weight: 800;
	font-size: 0.9rem;
	margin: 0;
	color: #868686;
`

const Date = styled.p`
	font-size: 0.9rem;
	margin: 0;
	color: #868686;
`

const DriversExperienceImage = styled(GatsbyImage)``

export default DriversExperienceTemplate

    export const query = graphql`
      query PageQuery617($id: String!) {
				wpDriversExperience(id: {eq: $id}) {
					
	id
	title
	uri
	date
	postContent {
		brand
		model
		authorName
		rating
		content
		image {
			altText
			sourceUrl
			localFile {
				childImageSharp {
					gatsbyImageData(width: 700 height: 450)
				}
			}
		}
	}
	seo {
		
		title
		metaDesc
		focuskw
		metaKeywords
		metaRobotsNoindex
		metaRobotsNofollow
		opengraphTitle
		opengraphDescription
		opengraphImage {
			altText
			sourceUrl
			srcSet
		}
		twitterTitle
		twitterDescription
		twitterImage {
			altText
			sourceUrl
			srcSet
		}
		canonical
		cornerstone
		schema {
			articleType
			pageType
			raw
		}
  
	}
  
				}
      }
    `
  
